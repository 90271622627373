import React from 'react'
import { graphql } from 'gatsby'

import { Cards, Card, ContactCard } from '@accodeing/gatsby-theme-switsbake'

import Layout from 'components/Layout'
import CompanyContactDetails from 'query/contactDetails'

const massageQuery = (query) =>
  query.edges.map((edge) => {
    const node = edge.node

    return {
      name: node.name,
      title: node.title,
      phone: node.phone,
      email: node.email,
      link: node.link,
    }
  })

const Kontakt = ({ data }) => {
  const contacts = massageQuery(data.allGoogleSheetKontaktpersonerRow)
  const contactDetails = CompanyContactDetails()

  return (
    <Layout
      pageTitle='Kontakt'
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row'>
        <section
          className='section section--with-theme-color 
      section--centered section--small-padding section--with-corners'
        >
          <h1 className='page__heading page__heading--bright'>Kontakt</h1>
          <Cards className='heimr-cards--themed'>
            <Card title='Kontakt' themed={true}>
              <p>
                E-post:
                <oma-link
                  class='section__link section__link--themed'
                  to={contactDetails.email}
                />{' '}
                <br />
                Telefon:
                <oma-link
                  class='section__link section__link--themed'
                  to={contactDetails.phone}
                />{' '}
                <br />
                Fax: {contactDetails.fax}
              </p>
            </Card>
            <Card title='Adress' themed={true}>
              <p>
                {contactDetails.postAddress.company} <br />
                {contactDetails.postAddress.address} <br />
                {contactDetails.postAddress.zipCode}{' '}
                {contactDetails.postAddress.location}
              </p>
            </Card>
            <Card title='Besöksadress' themed={true}>
              <p>
                {contactDetails.visitAddress.company} <br />
                {contactDetails.visitAddress.address} <br />
                {contactDetails.visitAddress.zipCode}{' '}
                {contactDetails.visitAddress.location}
              </p>
            </Card>
          </Cards>
        </section>
        <Cards className='section section--with-cards section--centered'>
          {contacts.map((contact, index) => (
            <ContactCard
              name={contact.name}
              jobTitle={contact.title}
              phone={contact.phone}
              email={contact.email}
              link={contact.link}
              key={index}
            />
          ))}
        </Cards>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "flying-cupcakes.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    allGoogleSheetKontaktpersonerRow {
      edges {
        node {
          name
          title
          phone
          email
          link
        }
      }
    }
  }
`

export default Kontakt
